.edit-form{
    /* Center vertically */
    width: 850px;
    height: 50s0px; 
    background-color: white;
    
    border-radius: 10px;
    display: flex;
    flex-direction: column;

}
.addess{
    display: flex;
    flex-direction: column;
}
.heading{
   text-align: center;
   font-family: "Inter-Medium";
   display: flex;
   align-items: center;
   margin-top: 20px;
}

.image1{
    width: 13px;
height: 13px;
cursor: pointer;

}
.input{
    border-radius: 5px;
    height: 30px;
    width: 250px;
    border-color: #E9E9E9;
    border-style: solid;
    border-width: 1px;
    font-family: "Inter-Medium";
}
.input1{
    border-radius: 5px;
    height: 30px;
    width: 520px;
    border-color: #E9E9E9;
    border-style: solid;
    border-width: 1px;
    font-family: "Inter-Medium";
}
