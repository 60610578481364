 .slides {


display: flex;


overflow-x: auto;


scroll-snap-type: x mandatory;


}


.slides > .insideDiv {


display: block;


flex-shrink: 0;


width: 40%;
 
border-radius: 1em;


height: 200px;


scroll-snap-align: start;


scroll-behavior: smooth;



box-shadow: 0px 0px 2px 2px   #F0F0F0;
margin: 1em;











}



