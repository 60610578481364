.tables{
    box-shadow: 0px 2px 4px -2px #1018280F;

    box-shadow: 0px 4px 8px -2px #1018281A;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    
    }

.table-heading th{
    background: #F9FAFB;
  
}


tr.table-row:hover {
    background-color: #f2f2f2; 
  }