.tables{
    box-shadow: 0px 2px 4px -2px #1018280F;

    box-shadow: 0px 4px 8px -2px #1018281A;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    
    }

.table-heading th{
    background: #F9FAFB;

}


tr.table-row:hover {
    background-color: #f2f2f2; 
  }

  .FIB-button{
    background-color: rgb(177, 226, 240) !important;
    border:1px solid rgb(45, 169, 203) !important;
    color: rgb(45, 169, 203) !important;
  }
  .FIB-text{
    padding: 4px;
    border-radius:8px ;
    background-color: rgb(177, 226, 240);
  }
  .FIB-count{
    background-color: rgb(45, 169, 203);
  }
  .GBP-button{
    background-color: #FEEDEE !important;
    border:1px solid #d32028 !important;
    color: #d32028 !important;



  }
  .GBP-text{
    padding: 4px;
    border-radius:8px ;
    background-color:#FEEDEE
  }
  .GBP-count{
    background-color: #d32028
  }