body {
  margin: 0;
  padding: 0;
  background: #F5F5F5 !important;
  height: 100%;
   
}
html{
  scroll-behavior: smooth;
}

@font-face {
  font-family: Inter-Bold;
  src: url("./assets/font-family/Inter-Bold.ttf");
}
@font-face {
  font-family:Inter-ExtraBold;
  src: url("./assets/font-family/Inter-ExtraBold.ttf");
}
@font-face {
  font-family:Inter-Light;
  src: url("./assets/font-family/Inter-Light.ttf");
}

@font-face {
  font-family:Inter-Medium;
  src: url("./assets/font-family/Inter-Medium.ttf");
}
@font-face {
  font-family:Inter-Regular;
  src: url("./assets/font-family/Inter-Regular.ttf");
}
@font-face {
  font-family:Inter-SemiBold;
  src: url("./assets/font-family/Inter-SemiBold.ttf");
}
@font-face {
  font-family:Outfit-Regular;
  src: url("./assets/font-family/Outfit-Regular.ttf");
}

@media screen and (min-width: 1200px) {
  .MuiContainer-root {
    max-width: 1082px !important;
  }
}
 .sideshdow {
  background: #FFFFFF !important ;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25) !important ;
}