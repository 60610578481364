.topbar{
  margin-top: 6%;
  padding: 10px;
  display:flex;
  border-bottom: 4px solid #f0f0f0;
  z-index: 1;
 
}

.textDropdown{
  color:black
}

.showNotifications{
  
  cursor:pointer;
  padding-left:5px;
  background-color:#F3F3F3;
  box-shadow:0px 0px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius:2em;
  width: 2.8em;
  height: 2.8em;
  z-index: 999;

 
  
}

.showNotifications>div {
  right: 60%;
  margin-top: -2.8em;
  height: 280px;
  width: 23em;
  overflow-y: hidden;
  transition: 200ms  ;
  transition-property:  initial;
  border-radius: 1.5em;
  border:  0.75px solid rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  background-color: #FFF;
  position: absolute;
  z-index: 999;
}
.notifications{
  cursor:pointer;
  padding-left:5px;
  background-color:#F3F3F3;
  box-shadow:0px 0px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius:2em;
  width: 2.8em;
  height: 2.8em;

}
.notifications>div{
  visibility: hidden;
  position: absolute;
}

.notificationHeading{
  padding:4px;
  margin-top:-1.5px;
  border-radius:5em;
  height:2.6em;
  border: 1px solid rgba(190, 190, 190, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  background-color:#F8F8f8
}