.fade-In{
  animation: fadeIn 2s;
  text-align:center;
  padding: 10px;
  padding-bottom: 30px;
  margin-top: 15px;
  border-radius: 10px;
  background-color: #fff8f4;
  border: 2px solid rgba(212, 33, 41, 0.15);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.divider{
  width: 2px;
  height: auto;
  background-color: #d320295d;
  box-shadow:0px 0px 6px 0px rgb(0, 0, 0,0.15);
  border-radius: 1em;
  
  padding-left: 0.2em;
  padding-right: 0.2em;
  margin-right: 1em;

}

.upload{
  background: #777373;
  height:2.5em;
  margin-top:1.8em;
  margin-left:1em;
  color:white;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.uploaded{
  background: rgba(69, 231, 69, 0.815);
  height:2.5em;
  margin-top:1.8em;
  margin-left:1em;
  border-radius: 10px;
  color:black;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.inputField{
  width: 100%;
  height: 2.5em;
  border-radius: 10px;
  border: 1px solid #DbDBDB;
  margin-left: 1em;
  padding-left:5px ;
}