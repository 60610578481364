.navbar{
    background-color: #ffff;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
}
.logo{
    width: 180px;
    margin-left: 120px;
    height: 60px;
}
.list-card{
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 300px;
    list-style-type: none;

}
li{
    font-family: "Inter";
    font-weight: bold;
    font-size: 15px;
}