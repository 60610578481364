.noteText {
  width: 263px;
}

.switchDiv{
    padding-top: 1px;
    border: 1px solid #D0D0D0 ;
    width: 15em;
    border-radius: 10px;
    height:2.6em;
}


 .switchOff{
  
  border-radius:7px;
  cursor:pointer;
  padding-left: 2em;
  box-shadow: 0px 0px 1px 1px #D0D0D0;
  background-color:#d32028;
  color:white;
  text-align:center;
  height:2.4em;
  
  width:7em;
  font-weight:500;
  float:right;
  transition: 1s;
  
 }
 .switchOff::before{
    
    content: "Flat";
    color:white;
    margin-left: -5em;
    margin-right: 2em;
 }

 
 .switch{
  border-radius:7px;
  cursor:pointer;
  padding-left: 0.8em;
  box-shadow: 0px 0px 1px 1px #D0D0D0;
  background-color:#d32028;
  color:white;
  text-align:center;
  height:2.4em;
  width:6em;
  transition: 0.4s;
  font-weight:500;
  
 }
 .switch::after{
    
    content: "Per Annum";
    color:white;
    margin-left: 4em;
 }

  
 .switchOffNo{
  
  border-radius:7px;
  cursor:pointer;
  
  box-shadow: 0px 0px 1px 1px #D0D0D0;
  background-color:#d32028;
  color:white;
  text-align:center;
  height:2.4em;
  padding-left: 2em;
  width:6em;
  font-weight:500;
  float:right;
  transition: 1s;
  
 }
 .switchOffNo::before{
    
    content: "Yes";
    color:white;
    margin-left: -7.5em;
    margin-right: 2em;
 }
 .switchYes{
  border-radius:7px;
  cursor:pointer;
  padding-left: 1.6em;
  box-shadow: 0px 0px 1px 1px #D0D0D0;
  background-color:#d32028;
  color:white;
  text-align:center;
  height:2.4em;
  width:6em;
  transition: 0.4s;
  font-weight:500;
  
 }
 .switchYes::after{
    
    content: "No";
    color:white;
    margin-left: 3em;
 }

 
 .switchOffPF{
  
  border-radius:7px;
  cursor:pointer;
  
  box-shadow: 0px 0px 1px 1px #D0D0D0;
  background-color:#d32028;
  color:white;
  text-align:center;
  height:2.4em;
  padding-left: 2em;
  width:7em;
  font-weight:500;
  float:right;
  transition: 1s;
  
 }
 .switchOffPF::before{
    
    content: "Flat";
    color:white;
    margin-left: -5em;
    margin-right: 2em;
 }
 .switchPF{
  border-radius:7px;
  cursor:pointer;
  display: flex;
  
  box-shadow: 0px 0px 1px 1px #D0D0D0;
  background-color:#d32028;
  color:white;
  text-align:center;
  height:2.4em;
  width:7em;
  padding-left: 2em;
  transition: 0.4s;
  font-weight:500;
  
 }
 .switchPF::after{
    
    content: "Percentage";
    color:white;
    margin-left: 5em;
 }


 .tables{
   box-shadow: 0px 2px 4px -2px #1018280F;
   
   box-shadow: 0px 4px 8px -2px #1018281A;
   border-radius: 8px;
   border: 1px solid #EAECF0;
   margin-top: 20px;
   }

.table-heading th{
   background: #F9FAFB;
 
  
}

.table-body td{
 
   border-bottom: 1px solid #cccccc93;
  
}
tr.table-row:hover {
   background-color: #f2f2f2
  }
.placeholder-style::placeholder {
   padding-left: 5px;
   font-family: 'Inter-Medium';
   font-size: 14px;
   color: #cccccc; 
 }
 