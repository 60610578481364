*{
    font-family: 'Inter';
  }
  
  .login-form-container{
      display: flex;
      flex-direction: row;
      /* justify-content: space-around; */
      align-items: center;
     background-color: #FFFFFF;
     max-height:100vh;
     height: 100%;
     width: 100%;
     background-repeat: no-repeat;
     overflow:hidden;
  
       
  } 
  .login-background{
    background-color: #FFF2EE;
    /* background-image: url("../../assets/images/Vector1.png"); */
    background-repeat: no-repeat;
    background-size: 73% 90%;
    position: relative;
    box-shadow: 0px 8px 40px rgba(5, 5, 5, 0.38);
    border-radius: 18px;
  
   
  }
  
  .login-background-2{
    /* background-image: url("../../assets/images/Group_1.png"); */
    background-repeat: no-repeat;
    /* background-size: 70% 100%; */
  /* left:-30px; */
  
  }  
  
  .feemonk-image {
  
    position: absolute;
    top: 30%;
    left: 30%;
    height: 10%;
    width: 35%;
  }
  
  .caption{
  
    height: 50%;
    width: 50%;
    position: absolute;
    top: 45%;
    left: 25%;
    font-family:'Outfit';
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    line-height: 36px;
    /* or 120% */
  
    text-align: center;
  
    color: #000000;
  
  }
  
  
  
  
  .login-image{
      width: 100%;
      height: 100%;
      opacity: 0.1;
      position: relative;
      top: 0;
      left: 0;
  }
  
  .form-container{
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      /* border-radius: 8px; */
      /* box-shadow: 0px 8px 40px rgba(7, 7, 7, 0.08); */
      /* margin: 10%; */
      height: 100%;
      width: 30%;
  
      margin: auto;
      margin-top: 15%;
  
      /* margin-right:30px; */
      /* padding: 64px 48px 64px 48px; */
      /* width:400px;
      height:520px; */
     /* background-color: white; */
  
  }
  .input-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 100%;
    }
  
    .input-label {
      margin-bottom: 0px;
      font-family:'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #3B3A42;
    }
    
    .email-input-filed {
      font-size: 16px;
      height: 44px;
      border: 1px solid #d7dfe9;
      background-color: #FFFFFF;
      color: #64748b;
      border-radius: 8px;
      margin-top: 5px;
      padding: 8px 16px 8px 16px;
    }
    
    .password-input-field {
      font-size: 16px;
      height: 40px;
      border: 1px solid #d7dfe9;
      background-color: #FFFFFF;
      color: #64748b;
      border-radius: 8px;
      padding: 8px 16px 8px 16px;
    }
  
    .buttons-container1{
      /* display:flex;
      flex-direction: column;
      justify-content: space-around; */

      padding-top: 25px;
      
    }
    
    .login-button {
      color: #FFFFFF;
      height: 40px;
      width: 150px;
      margin-top: 20px;
      margin-bottom: 2px;
      margin-right: 16px;
      background-color:#D32028;
      border-radius: 16px;
      border: none;
      font-family:'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      width: 100%;
      
    }
  
    .error-message{
      font-size: 14px;
      height: 40px;
      color: red;
      font-family:'Inter';
      margin-top: 5px;
      padding: 8px 16px 8px 16px;
    }
  
    