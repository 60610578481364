.tables{
    box-shadow: 0px 2px 4px -2px #1018280F;
    
    box-shadow: 0px 4px 8px -2px #1018281A;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    margin-top: 10px;
    }
    .tables1{
      box-shadow: 0px 2px 4px -2px #1018280F;
      
      box-shadow: 0px 4px 8px -2px #1018281A;
      border-radius: 8px;
      border: 1px solid #EAECF0;
      width: 90%;
      margin-left: 15%;
      margin-bottom: 10px;
      margin-top: 5px;
      }
      .tables2{
        box-shadow: 0px 2px 4px -2px #1018280F;
        
        box-shadow: 0px 4px 8px -2px #1018281A;
        border-radius: 8px;
        border: 1px solid #EAECF0;
       
        }
  
  .table-heading th{
    background: #F9FAFB;
  
   
  }
  
  .table-body td{
  
    border-bottom: 1px solid #cccccc93;
   
  }
  tr.table-row:hover {
    background-color: #f2f2f2
    }
  .placeholder-style::placeholder {
    padding-left: 5px;
    font-family: 'Inter-Medium';
    font-size: 14px;
    color: #cccccc; 
  }
     