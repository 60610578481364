.inputClass{

  border:1px solid #ccc;
  height: 2.5em;
  padding-left: 5px;
  border-radius: 10px;
  margin: 20px;

}


.heading{
  font-family: Outfit;
  font-weight: bold;
}