.dropdown-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  
}
.button:hover {
  background-color: red; /* Background color on hover */
}
 
.hover-effect:hover {
    font-weight: 700;
    
   
}

  
  .one{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
  }
  
  
  
.dropdown-icon{
  margin-top: 26px;
  margin-right: 80px;
}
.dropup-icon{
  margin-top: 26px;
  
}
.dropdown-icon-home{
  
  margin-right: 80px;
}
.dropup-icon-home{
  
  
}
 
  .item-name{
    position: relative;
    text-align: left;
    color: #344054 ;
    font-family: "Inter-Medium";
    font-size: 15px;
  }
  
  .Home{
    height: 100%;
    position: fixed;
    background-color: white;
  }
 

  .count-select{
    background-color:#fff;
    border-radius:20px;
    border:1px solid #D0D0D0;
    border-width:1px;
    width:45px;
    display: inline;
    color:#777575;
    font-family:Inter-Medium;
    font-size:14px;
    margin-left: 10%;

  }

  .count{
    background-color:#D32028;
    border-radius:20px;
    border-style:none;
    border-width:1px;
    width:45px;
    display: inline;
    color:white;
    font-family:Inter-Medium;
    font-size:14px;
    margin-left: 10%;
  }

  .sidebar-data-open{
   
    margin-left: 1vw;
    transition: all 0.5s;
   
    -webkit-transition: all 0.25s;
    
  }
  