

.screens-container{
  display: flex;
}

 html, #root,  body, #app, #app>div {
  height: 100%
} 

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}