.resend{
    width: 400px;
height:280px;
margin-left: 45%;
background-color: white;
border-style: solid;
border-radius: 10px;
border-width: 1px;
display: flex;
flex-direction: column;
justify-content: flex-start;


}
.image{
    width: 40px;
height: 30px;
align-self: center;
margin-left: 170px;
margin-bottom: 20px;
}