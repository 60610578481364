*{
  margin: 0;
  padding: 0;
}



.form-container{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* border-radius: 8px; */
    /* box-shadow: 0px 8px 40px rgba(7, 7, 7, 0.08); */
    /* margin: 10%; */
    height: 100%;
    width: 30%;

    margin: auto;
    margin-top: 10%;

    /* margin-right:30px; */
    /* padding: 64px 48px 64px 48px; */
    /* width:400px;
    height:520px; */
   /* background-color: white; */

}
.form-container-new{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* border-radius: 8px; */
    /* box-shadow: 0px 8px 40px rgba(7, 7, 7, 0.08); */
    /* margin: 10%; */
    height: 100%;
    width: 30%;

    margin: auto;
    margin-top: 5%;

    /* margin-right:30px; */
    /* padding: 64px 48px 64px 48px; */
    /* width:400px;
    height:520px; */
   /* background-color: white; */

}

.family-image{
  z-index: 1;
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 15em;
  width: 15em;
}

.elipse{
    position: absolute;
    right: 0px;
    bottom: 0px;
    width:16em;

}

.input-filed{
font-family:Inter;
 border:1px solid rgb(226, 225, 225);
 width:100%;
 padding: 8px 10px;

 border-radius: 5px;

}

.submit{
  font-family:Inter;
  font-size: 13px;
  margin-top: 2em;
  border: none;
  background: #D32028;
  color: white;
  padding: 10px 18px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  gap: 8px;
  border-radius: 30px;
  width: 100%;

}