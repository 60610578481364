
.detailsContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 12px 0px 12px 18px;
  margin: 12px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px   #F0F0F0;
}

.fieldTitle{
  color:#D32038;
  font-weight:bold;
  font-size: 16px;
  font-family: 'Outfit';
}
.details{
  font-family: 'Outfit';
}