::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

 .slides {


display: flex;


overflow-x: auto;


scroll-snap-type: x mandatory;


}


.slides > div {


display: block;


flex-shrink: 0;


width: 30%;
 
border-radius: 1em;


height: 150px;


scroll-snap-align: start;


scroll-behavior: smooth;


border:none;
box-shadow: 0px 0px 1px 1px   #C0C0C0;
margin: 1em;
 

}

.accordionItem{

 margin-top:2em;
 border:none;
 box-shadow:0px 0px 2px 2px #F0F0F0;

}

.accordionHeader{
  background-color: aquamarine;
}

.sliderBack{
  cursor: pointer;
  transform: rotate(180deg);
  position:absolute;
  margin-left: -1.5em;
}

.wrapper {
    position: relative;
}

.wrapper > .bar {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events:none;
  
}

.barWrapper {
    width: 100%;
    overflow-x: scroll;
}
