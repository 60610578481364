.fields-container{
    background-color: #fbfeff97;
    width: 90%;
    border-radius: 5px;
    border: 0.2px solid #bfbfbf;
    padding: 0px 10px 8px 10px;
    margin-top: 0px !important;
    margin: auto;
    margin-bottom: 40px !important;
    font-family:'Outfit';
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.06);
}
.form-heading{
    font-family: "Outfit";
    font-weight: bold;
    text-align: start;
    margin-top: 15px;
    margin-bottom: 18px;
    align-self: flex-start;
    margin-left: 8px;
    font-size: 18px;
}

.files-container{
    border-radius: 5px;
    display: flex;
    flex-direction: column;

}
.file-card{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center  !important;
    background-color: #fff8f4;
    width: 70%;
    padding: 0px 5px 0px 5px;
    margin-bottom: 12px;
    box-shadow: 5px 4px 10px 1px #bfbfbf;
    box-shadow: 1px 1px 3px 1px #bfbfbf, 1px 1px 2px 0 #bfbfbf;
}

.file-card p{
    font-weight: bold;
    margin-top: 10px;
    font-family: 'Outfit';
    line-height: 1;

}
.file-count{
    font-weight: bold;
    text-align: start;
    margin-bottom: 0;

}
.submit-button {
    width: 300px;
    color: #ffffff;
    background-color: #D32028;
    font-family: 'Outfit', sans-serif;
    transition: all 0.3s ease;
  }
  
.submit-button:hover {
    background-color: #D32028;
    opacity: 0.9;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 1);
    cursor: pointer !important;
}
  