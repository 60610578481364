.file-card{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center  !important;
    background-color: #fff8f4;
    width: 300px !important;
    padding: 0px 5px 0px 5px;
    margin-bottom: 12px;
    box-shadow: 5px 4px 10px 1px #bfbfbf;
    box-shadow: 1px 1px 3px 1px #bfbfbf, 1px 1px 2px 0 #bfbfbf;

}

