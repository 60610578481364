.profileHighlight{
  display: flex;
  justify-content: space-between;
  margin-left: 1em;
  margin-right: 1em;
}

.tabsHeading{
  color:#626872;
  font-weight:bold;
  font-size:'12px';
  cursor: pointer;
}
.tabsHeadingClicked{
  color:#D32028;
  font-weight:bold;
  font-size:'12px';
  cursor: pointer;
  

}

.sliderBack{
  cursor: pointer;
  transform: rotate(180deg);
  position:absolute;
  margin-left: -1.5em;
}
 

.customerDashboardButton {
  font-weight: normal;
  color: #0A79DF;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;
}

.customerDashboardButton:hover {
  color: #0963b7;
}
.field-title{
  color:#D32038;
  font-weight:bold
}