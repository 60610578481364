.tables{
    box-shadow: 0px 2px 4px -2px #1018280F;
    
    box-shadow: 0px 4px 8px -2px #1018281A;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    margin-top: 10px;
    }
  
  .table-heading th{
    background: #F9FAFB;
  
   
  }
  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;
  }
  
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .table-body td{
  
    border-bottom: 1px solid #cccccc93;
   
  }
  tr.table-row:hover {
    background-color: #f2f2f2
    }
  .placeholder-style::placeholder {
    padding-left: 5px;
    font-family: 'Inter-Medium';
    font-size: 14px;
    color: #cccccc; 
  }