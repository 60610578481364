.fp-container{
    padding: 10px 0px 8px 0px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    width:170px;
    margin-right:18px;
    margin-bottom:20px;
    display:flex;
    flex-direction:column;
    align-items:center;
    
}

.file-name{
    font-family: "Outfit";
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
}