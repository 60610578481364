.chatContainer{
  border-radius: 12px;
  border:1px solid #D32028;
  background-image: url('chatBackground.png');
  
  background-size: auto;
  padding: 2em;
  height: 100%;
}


.receiver{
  opacity: 1;
  margin: 2em;
  background-color: #FFEBD2;
  border: 1px solid #FFBE6C;
  border-radius: 12px;
  margin-bottom: 4em;
  margin-top: 2.5em;
  width: 50%;
  padding: 1em;
}

.sender{
  background-color: #DBE8FF;
  border: 1px solid #9CB7E6;
  border-radius: 12px;
  margin-left: 50%;
  width: 49%;
  padding: 1em;
  margin-bottom: 4em;
}